const storages = {
    1: {
        key: 'rickRobertsWayStorageLocation',
        location: {
            Lat: 51.53433,
            Lng: -0.00429,
        },
        address: 'Rick Roberts Way, Stratford, London E15 2FP',
    },
    2: {
        key: 'marshWayStorageLocation',
        location: {
            Lat: 51.50998,
            Lng: 0.17272,
        },
        address: '35a Marsh Way, Rainham RM13 8UH',
    },
};
export const getStorageAddressById = (id) => {
    return storages[id];
};
export const getStorageItemsFromClient = (client) => {
    const items = client.spaces.reduce((acc, space) => {
        const itemIndex = acc.findIndex(accItem => space.items.some(item => item.key === accItem.key));
        console.log('itemIndex', itemIndex);
        if (itemIndex !== -1) {
            acc[itemIndex] = {
                ...acc[itemIndex],
                amount: acc[itemIndex].amount + space.items.reduce((acc, item) => acc + item.amount, 0),
            };
        }
        else {
            acc.push(...space.items);
        }
        return acc;
    }, []);
    return items;
};
export const StatusCodeToText = {
    BOOKED: 'Booked',
    CONFIRMED: 'Confirmed',
    PROPOSAL: 'Proposal',
    CALCULATED: 'Calculated',
    SEND_INVOICE: 'Send invoice',
    PAYMENT_PENDING: 'Payment pending',
    EXECUTORS_APPOINTED: 'Executors appointed',
    WORK_IN_PROGRESS: 'Work in progress',
    MOVE_EXECUTED: 'Move executed',
    COMPLETED: 'Completed',
    CANCELLED_LEAD: 'Cancelled lead',
    CANCELLED_MOVE: 'Cancelled move',
    NO_PICKUP: 'No pickup',
    CALL_BACK: 'Call back',
    NEW_REQUEST: 'New',
    PAID_ONLINE: 'Paid online',
};
export const StatusCodeToColor = {
    BOOKED: '#daf5de',
    CONFIRMED: '#daf5de',
    PROPOSAL: '#daf5de',
    CALCULATED: '#daf5de',
    SEND_INVOICE: '#daf5de',
    PAYMENT_PENDING: '#daf5de',
    EXECUTORS_APPOINTED: '#daf5de',
    WORK_IN_PROGRESS: '#daf5de',
    MOVE_EXECUTED: '#daf5de',
    COMPLETED: '#daf5de',
    CANCELLED_LEAD: '#ffbeb7',
    CANCELLED_MOVE: '#ffbeb7',
    NO_PICKUP: '#ffbeb7',
    CALL_BACK: '#daf5de',
    NEW_REQUEST: '#daf5de',
    PAID_ONLINE: '#daf5de',
};
