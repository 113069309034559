import styled from 'styled-components';
export const InfoRow = styled.div `
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  span {
    font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};
  }
`;
export const ColumnFlexContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
