import React from 'react';
import { Clients, Main, Storages, StoragePage, UnitPage, Client, StorageReturn, ReturnInfo } from '#Pages';
import { Route, Routes } from 'react-router-dom';
import { Container } from '#Components/CommonStyled';
import AddNewSpace from '#Pages/AddNewSpace/AddNewSpace';
function App() {
    return (React.createElement("div", { className: "App" },
        React.createElement(Container, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(Main, null) }),
                React.createElement(Route, { path: "/storage", element: React.createElement(Storages, null) }),
                React.createElement(Route, { path: "/storage/:storageId", element: React.createElement(StoragePage, null) }),
                React.createElement(Route, { path: "/storage/:storageId/:unitId", element: React.createElement(UnitPage, null) }),
                React.createElement(Route, { path: "/storage/:storageId/:unitId/add", element: React.createElement(AddNewSpace, null) }),
                React.createElement(Route, { path: "/storage/:storageId/:unitId/:spaceId", element: React.createElement(AddNewSpace, { created: true }) }),
                React.createElement(Route, { path: "/clients", element: React.createElement(Clients, null) }),
                React.createElement(Route, { path: "/clients/:clientId", element: React.createElement(Client, null) }),
                React.createElement(Route, { path: "/clients/:clientId/return", element: React.createElement(StorageReturn, null) }),
                React.createElement(Route, { path: "/clients/:clientId/return/:returnId", element: React.createElement(ReturnInfo, null) })))));
}
export default App;
