import { BlockWithIcon, Button, FormInput, Header } from '#Components';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '#Services/apiService';
import { useErrorToastEffect, useStores, useSuccessToastEffect } from '#Hooks';
import DatePickerBlock from '#Components/AddSpace/components/DatePickerBlock';
import { ArrowRight, FolderIcon } from '#Images';
import ListModal from '#Components/AddSpace/components/ListModal';
import { duration } from '../../constants/testData';
import Loader from '#Components/shared/Loader';
import { observer } from 'mobx-react-lite';
import ClientItemsBlock from './ClientItemsBlock';
import { ButtonKinds } from '#Components/shared/Button/Button';
import { TextArea } from '#Components/CommonStyled';
import ClientCommentBlock from './ClientCommentBlock';
import { v4 as uuidv4 } from 'uuid';
import { TopButtonsWrapper } from '#Components/StorageReturn/shared/components';
import SpaceInfoLink from '#Components/shared/SpaceInfoLink';
import ReturnsBlock from '#Components/ReturnsBlock/ReturnsBlock';
const api = new ApiService();
const Client = () => {
    const { appData } = useStores();
    const commentTextareaRef = useRef(null);
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [openedModals, setOpenedModals] = useState({
        duration: false,
        plans: false,
    });
    const [clientData, setClientData] = useState({
        current_plan: '',
        current_price_per_week: '',
        duration: '',
    });
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ['client', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getClient(Number(clientId));
        },
    });
    const returnsData = useQuery({
        queryKey: ['returns', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getReturnOrders(Number(clientId));
        },
    });
    useErrorToastEffect(error, 'Error with client loading');
    useErrorToastEffect(appData.error, 'Error with unit loading');
    useSuccessToastEffect(appData.success ? 'Client successfully updated' : '');
    useEffect(() => {
        if (data) {
            setClientData({
                current_plan: data.current_plan,
                current_price_per_week: data.current_price_per_week,
                duration: data.duration,
            });
        }
    }, [appData.process, data]);
    useEffect(() => {
        if (!appData.storagePlans.length) {
            appData.fetchStoragePlans();
        }
    }, []);
    const onChangeClientData = (data, key) => {
        setClientData({ ...clientData, [key]: data });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const onSaveChanges = async () => {
        const newComment = {
            id: uuidv4(),
            content: commentTextareaRef.current?.value || '',
        };
        const floatPrice = parseFloat(clientData.current_price_per_week || '');
        await appData.updateClientInfo(Number(clientId), {
            current_plan: clientData.current_plan || '',
            current_price_per_week: floatPrice,
            duration: clientData.duration || '',
            comments: [...(data?.comments || []), newComment],
        });
        refetch();
    };
    if (isLoading || returnsData.isLoading) {
        return React.createElement(Loader, null);
    }
    return (React.createElement(React.Fragment, null,
        appData.process && React.createElement(Loader, null),
        React.createElement(Header, { title: data?.first_name || 'Client', subtitle: `Client ID ${clientId}` }),
        !!data?.storage_location_ids?.length && data?.storage_location_ids?.length < 3 && (React.createElement(TopButtonsWrapper, null,
            React.createElement(Button, { kind: ButtonKinds.primary, style: { marginBottom: 20 }, onClick: () => navigate(`/clients/${clientId}/return?full=1`), full: true }, "Full return"),
            React.createElement(Button, { kind: ButtonKinds.primary, style: { marginBottom: 20 }, onClick: () => navigate(`/clients/${clientId}/return`), full: true }, "Partial return"))),
        data?.spaces.map(space => (space.items.length ? React.createElement(SpaceInfoLink, { space: space }) : null)),
        React.createElement(ReturnsBlock, { returns: returnsData.data || [] }),
        React.createElement(BlockWithIcon, { iconUrl: FolderIcon, title: "Order details" },
            React.createElement(FormInput, { disabled: true, bottomMargin: 12, name: "First name", onChange: val => onChangeClientData(val, 'first_name'), value: data?.first_name || '' }),
            React.createElement(FormInput, { disabled: true, bottomMargin: 12, name: "Last name", onChange: val => onChangeClientData(val, 'last_name'), value: data?.last_name || '' }),
            React.createElement(FormInput, { rightIconUrl: ArrowRight, bottomMargin: 12, name: "Current plan", onFocus: () => setOpenedModals({ ...openedModals, plans: true }), onChange: () => { }, value: clientData.current_plan || '' }),
            React.createElement(FormInput, { type: "number", bottomMargin: 12, name: "Price per week / \u00A3", onChange: v => onChangeClientData(v, 'current_price_per_week'), value: `${clientData.current_price_per_week || ''}` }),
            React.createElement(FormInput, { readonly: true, rightIconUrl: ArrowRight, bottomMargin: 12, name: "Duration", onFocus: () => setOpenedModals({ ...openedModals, duration: true }), onChange: val => onChangeClientData(val, 'duration'), value: clientData?.duration || '' }),
            React.createElement(DatePickerBlock, { disabled: true, title: "Start date", date: data?.start_date ? new Date(data?.start_date) : null, onSelectDate: () => { } }),
            React.createElement(DatePickerBlock, { disabled: true, title: "Estimated end date", date: data?.estimated_end_date ? new Date(data?.estimated_end_date) : null, onSelectDate: () => { } }),
            React.createElement(DatePickerBlock, { disabled: true, title: "End date", date: data?.end_date ? new Date(data?.end_date) : null, onSelectDate: () => { } }),
            React.createElement(TextArea, { placeholder: "Add comment", style: { height: '100px' }, ref: commentTextareaRef })),
        React.createElement(Button, { loading: appData.process, kind: ButtonKinds.primary, style: { marginBottom: 20 }, onClick: onSaveChanges, full: true }, "Save changes"),
        React.createElement(ClientCommentBlock, { comments: data?.comments || [] }),
        React.createElement(BlockWithIcon, { iconUrl: FolderIcon, title: "Inventory" }, !!data?.spaces.length ? (data.spaces.map(space => (space.items.length ? React.createElement(ClientItemsBlock, { space: space }) : null))) : (React.createElement("p", null, "No items stored yet"))),
        openedModals.plans && (React.createElement(ListModal, { selectedItem: clientData?.current_plan, onClose: () => setOpenedModals({ ...openedModals, plans: false }), list: appData.storagePlans.map(plan => ({ key: plan, value: plan })), onItemClick: k => onChangeClientData(k, 'current_plan'), title: "Current plan" })),
        openedModals.duration && (React.createElement(ListModal, { selectedItem: clientData.duration, onClose: () => setOpenedModals({ ...openedModals, duration: false }), list: duration.map(term => ({ key: term, value: term })), onItemClick: k => onChangeClientData(k, 'duration'), title: "Duration" }))));
};
export default observer(Client);
