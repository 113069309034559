import { TextArea } from '#Components/CommonStyled';
import React from 'react';
import styled from 'styled-components';
const ItemContainer = styled.div `
  font-weight: 400;
  font-size: 16px;
  /* padding: 8px 0; */
  height: 48px;
  line-height: 22px;
  justify-content: space-between;
  display: flex;
  gap: 32px;

  align-items: center;
  border-bottom: none;

  ${({ $inModal }) => $inModal &&
    `
    :not(:last-child) {
    border-bottom: 1px solid #e6ebef;
    height: 56px;
    }

  `}
`;
const BoxInput = styled.input `
  width: 56px;
  height: 40px;
  padding: 12px;
  border-radius: 12px;
  font-size: 16px;
  border: none;
  text-align: center;
  background: transparent;
  background-color: var(--main-light-gray);
`;
const ControllerContainer = styled.div `
  display: flex;
  align-items: center;
  background-color: ${({ $disabled }) => ($disabled ? 'transparent' : 'var(--main-light-gray)')};
  border-radius: 12px;
`;
const LeftItemBlock = styled.div `
  display: flex;
  align-items: center;

  button {
    margin-right: 8px;
  }
`;
const ViewItem = ({ amount, title, comment }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ItemContainer, { "$inModal": false },
            React.createElement(LeftItemBlock, null,
                React.createElement("p", null, title)),
            React.createElement(ControllerContainer, { "$disabled": false },
                React.createElement(BoxInput, { style: { fontWeight: 600 }, disabled: true, value: amount }))),
        comment && React.createElement(TextArea, { disabled: true, placeholder: "", value: comment })));
};
export default ViewItem;
