import { isMobileSafari } from 'react-device-detect';
import dayjs from 'dayjs';
export const declOfNum = (num, text_forms) => {
    const n = Math.abs(num) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 === 1) {
        return text_forms[0];
    }
    return text_forms[2];
};
export const getCurrentHeight = () => {
    let myHeight = 0;
    if (typeof window.innerWidth === 'number') {
        myHeight = window.innerHeight;
    }
    else if (document.documentElement && document.documentElement.clientHeight) {
        myHeight = document.documentElement.clientHeight;
    }
    else if (document.body && document.body.clientHeight) {
        myHeight = document.body.clientHeight;
    }
    return myHeight;
};
export const scrollToTop = () => {
    setTimeout(() => {
        if (isMobileSafari) {
            window.scrollTo(0, 70);
        }
        else {
            document.querySelector('#root')?.scrollIntoView({ behavior: 'smooth' });
        }
    }, 100);
};
export const getStringDateFromTimeSlot = (slotTime, date) => {
    const jobStartWithUTCOffset = new Date(new Date(date || new Date()).setHours(slotTime, 0, 0, 0));
    jobStartWithUTCOffset.setTime(jobStartWithUTCOffset.getTime() - jobStartWithUTCOffset.getTimezoneOffset() * 60000);
    return jobStartWithUTCOffset.toISOString();
};
export const getNumberFromString = (str) => {
    const num = parseInt(str, 10);
    return isNaN(num) ? 0 : num;
};
export const getFloatNumberFromString = (str) => {
    const num = parseFloat(str);
    return isNaN(num) ? 0 : num;
};
// export const getFloatNumberFromString = (str: string) => {
//   const cleanedStr = str.replace(/[^0-9.]/g, '');
//   const num = parseFloat(cleanedStr);
//   if (!num || isNaN(num)) {
//     return 0;
//   }
//   return cleanedStr;
// };
export const addSlotToDate = (date, slot) => {
    const newDateFrom = dayjs(date).set('hours', slot).set('minutes', 0).toDate();
    return newDateFrom;
};
export function toIsoString(date) {
    const londonOffset = isWinterTime(date) ? 0 : 60;
    const pad = function (num) {
        return (num < 10 ? '0' : '') + num;
    };
    return (date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        '+' +
        pad(Math.floor(Math.abs(londonOffset) / 60)) +
        ':' +
        pad(Math.abs(londonOffset) % 60));
}
export const isWinterTime = (date) => {
    const options = {
        timeZone: 'Europe/London',
        timeZoneName: 'short',
    };
    /* @ts-ignore */
    const londonTimeZone = new Intl.DateTimeFormat('en-GB', options)
        .formatToParts(date)
        .find(part => part.type === 'timeZoneName').value;
    const isWinterTime = londonTimeZone.includes('GMT');
    return isWinterTime;
};
export const hoursToHumanReadable = (hours) => {
    const hoursString = String(Math.floor(hours));
    const minutesString = String(Math.round((hours - Math.floor(hours)) * 60));
    return `${hoursString}h ${minutesString}m`;
};
export function createEmptyArray(length) {
    return new Array(length).fill(0);
}
export const emulateResponse = (data, timeout) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(data);
        }, timeout);
    });
};
export const getRandomId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
export const getSpaceName = (space) => {
    return `Space - ${space.user_name} - ${space?.hubspot_order_id || ''}`;
};
export const isTwoHoursSlot = (slot) => {
    return slot[1] - slot[0] === 2;
};
export const getTimeSlotHours = (slot) => {
    return slot[1] - slot[0];
};
export const getTimeSlotFromDateString = (date) => {
    const dateTime = new Date(date);
    const slot = dateTime.getHours();
    return slot;
};
export const calculateTotalItems = (items) => {
    return items.reduce((acc, item) => acc + item.amount, 0);
};
