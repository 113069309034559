import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import ApiService from '#Services/apiService';
import AppData from './AppData';
const api = new ApiService();
class SpaceData {
    allItems = [];
    selectedItems = [];
    mediaFiles = [];
    comment = '';
    comments = [];
    isEdit = false;
    constructor() {
        makeAutoObservable(this);
    }
    clean = () => {
        this.selectedItems = [];
        this.mediaFiles = [];
        this.comment = '';
        this.comments = [];
    };
    fetchStorageItems = async () => {
        try {
            const items = await api.getItems();
            this.allItems = items.map(item => ({
                ...item,
                amount: 1,
            }));
        }
        catch (error) {
            console.log('Error with items loading');
        }
    };
    onDeleteSpace = async (storageId, unitId, spaceId, body) => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            await api.deleteSpace(storageId, unitId, spaceId, body);
            return true;
        }
        catch (error) {
            AppData.setError('Problem with space deleting');
            return false;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    onTransferSpace = async (unitId, storageId, spaceId, newUnitId) => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            const transferObj = {
                unit_id: newUnitId,
            };
            await api.patchTransferSpace(transferObj, storageId, unitId, spaceId);
            return true;
        }
        catch (error) {
            AppData.setError('Problem with space transferring');
            return false;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    onPatchSpace = async (data, unitId, storageId, spaceId) => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            await api.putSpace(data, storageId, unitId, spaceId);
            return true;
        }
        catch (error) {
            AppData.setError('Problem with space updating');
            return false;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    onAddSpace = async (unitId, storageId, orderData, spaceId) => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            // const mediaIds = this.mediaFiles.map(file => file.id);
            const mediaItems = this.mediaFiles.map(item => ({
                id: item.id,
                content_type: item.content_type,
                url: item.url,
                ...(item.thumbnail ? { thumbnail: item.thumbnail } : {}),
            }));
            const newComment = {
                id: uuidv4(),
                content: this.comment,
            };
            const data = {
                comments: [...this.comments, ...(this.comment ? [newComment] : [])],
                items: this.selectedItems,
                media: mediaItems,
                ...orderData,
            };
            spaceId
                ? await api.putSpace(data, storageId, unitId, spaceId)
                : await api.postAddNewSpace(data, storageId, unitId);
            return true;
        }
        catch (error) {
            AppData.setError('Problem with space adding');
            return false;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    setSpaceData = (data) => {
        this.selectedItems = data.items;
        this.mediaFiles = data.media;
        this.comments = data.comments;
    };
    setIsEdit = (isEdit) => {
        this.isEdit = isEdit;
    };
    setComment = (comment) => {
        this.comment = comment;
    };
    removeComment = (commentId) => {
        this.comments = this.comments.filter(comment => comment.id !== commentId);
    };
    setMediaFiles = (files) => {
        console.log('AAA', files);
        this.mediaFiles = files;
    };
    addMediaItem = (image) => {
        this.mediaFiles = [...this.mediaFiles, image];
    };
    removeMediaItem = (fileId) => {
        this.mediaFiles = this.mediaFiles.filter(file => file.id !== fileId);
    };
    onSetItemQty = (key, qty) => {
        const itemToChange = this.selectedItems.find(obj => obj.title === key);
        if (itemToChange && qty !== null) {
            itemToChange.amount = qty;
        }
        else {
            this.selectedItems = this.selectedItems.filter(item => item.title !== key);
        }
    };
    setFurnitureItems = (items) => {
        this.selectedItems = items;
    };
    addFurnitureItem = (item) => {
        this.selectedItems = [...this.selectedItems, item];
    };
    addNewFurnitureItem = (item) => {
        this.allItems = [...this.allItems, item];
    };
    removeFurnitureItem = (key) => {
        this.selectedItems = this.selectedItems.filter(item => item.title !== key);
    };
}
export default new SpaceData();
