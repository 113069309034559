import { makeAutoObservable, runInAction } from 'mobx';
import ApiService from '#Services/apiService';
import dayjs from 'dayjs';
import { addSlotToDate, getTimeSlotHours, toIsoString } from '#Utils/helpers';
import { getStorageAddressById } from '#Services/storage';
import AppData from './AppData';
const api = new ApiService();
const defaultAddress = {
    type: 'house',
    address: '',
    postcode: '',
    elevator: true,
    parking: true,
    floor: '',
    location: { lat: 0, lng: 0 },
};
class StorageReturnData {
    date = dayjs().add(7, 'day').toDate();
    isSixHoursTimeSlots = true;
    timeSlot = [0, 0];
    totalPrice = 0;
    calculationId = null;
    clientInfo = null;
    address = defaultAddress;
    currentStepIndex = 0;
    items = [];
    contactData = {
        email: '',
        phone: '',
        comment: '',
    };
    constructor() {
        makeAutoObservable(this);
    }
    clean = () => {
        this.currentStepIndex = 0;
        this.address = defaultAddress;
        this.date = dayjs().add(7, 'day').toDate();
        this.isSixHoursTimeSlots = true;
        this.timeSlot = [0, 0];
        this.totalPrice = 0;
        this.calculationId = null;
        this.items = [];
        this.contactData = {
            email: '',
            phone: '',
            comment: '',
        };
        this.clientInfo = null;
    };
    setCurrentStepIndex = (index) => {
        this.currentStepIndex = index;
    };
    setClientInfo = (client) => {
        this.clientInfo = {
            first_name: client.first_name,
            last_name: client.last_name,
            storage_id: client.storage_location_ids[0],
            items: client.spaces.reduce((acc, space) => {
                acc.push(...space.items);
                return acc;
            }, []),
        };
    };
    setIsSixHrsTimeSlot = (v) => {
        this.isSixHoursTimeSlots = v;
    };
    setAddress = (address) => {
        this.address = address;
    };
    setDate = (date) => {
        this.date = date;
    };
    setTimeSlot = (timeSlot) => {
        this.timeSlot = timeSlot;
    };
    setItems = (items) => {
        this.items = items;
    };
    updateItem = (item) => {
        const index = this.items.findIndex(i => i.key === item.key);
        if (index !== -1) {
            this.items[index] = item;
        }
    };
    returnDataToCalcDto = (id) => {
        const startDate = toIsoString(addSlotToDate(this.date, this.timeSlot[0]));
        return {
            moving: {
                datetime: startDate,
                slot_duration_hours: getTimeSlotHours(this.timeSlot),
                destination_location: {
                    address: this.address.address,
                    elevator: this.address.elevator,
                    floor: Number(this.address.floor),
                    location: this.address.location,
                    parking: this.address.parking,
                    type: this.address.type,
                },
                items: this.items.map(item => ({
                    amount: item.amount,
                    key: item.category || 'other',
                    comment: item.comment,
                    title: item.title,
                })),
                origin_location: {
                    address: getStorageAddressById(id).address,
                    elevator: true,
                    floor: 0,
                    location: {
                        lat: getStorageAddressById(id).location.Lat,
                        lng: getStorageAddressById(id).location.Lng,
                    },
                    parking: true,
                    type: 'house',
                },
            },
        };
    };
    createOrder = async (userId) => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            const hubSpotId = await api.postCreateHubSpotOrder({
                calculation_id: this.calculationId || '',
                user_id: userId,
            });
            return hubSpotId.deal_id;
        }
        catch (error) {
            AppData.setError('Problem with order adding');
            console.log(error);
            return null;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    calculatePrice = async () => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            const res = await api.calculateOrder(this.returnDataToCalcDto(this.clientInfo.storage_id));
            runInAction(() => {
                this.totalPrice = res.response.moving.total_price;
                this.calculationId = res.id;
            });
        }
        catch (error) {
            AppData.setError('Problem with space adding');
            console.log(error);
            return false;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    sendData = async () => {
        try {
            AppData.setError(null);
            AppData.setProcess(true);
            const res = await api.calculateOrder(this.returnDataToCalcDto(this.clientInfo.storage_id));
            runInAction(() => {
                this.totalPrice = res.response.moving.total_price;
            });
        }
        catch (error) {
            AppData.setError('Problem with space adding');
            console.log(error);
            return false;
        }
        finally {
            AppData.setProcess(false);
        }
    };
    setContactData = (data) => {
        this.contactData = data;
    };
}
export default new StorageReturnData();
