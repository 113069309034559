export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const twoHoursTimeSlots = [
    [9, 11],
    [10, 12],
    [12, 14],
    [13, 15],
    [14, 16],
    [17, 19],
    [18, 20],
    [19, 21],
];
export const sixHoursTimeSlots = [
    [9, 15],
    [12, 18],
    [17, 23],
];
