import React, { useEffect } from 'react';
import { InfoRow, ColumnFlexContainer } from '../shared/total-sheet/components';
import { useErrorToastEffect, useStores } from '#Hooks';
import dayjs from 'dayjs';
import ViewItem from '../shared/ViewItem';
import { Button } from '#Components/shared';
import { BottomButtonsWrapper } from '../shared/components';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
const TotalSheet = () => {
    const { storageReturnData: { totalPrice, calculatePrice, date, timeSlot, address, items, createOrder }, appData: { process, error }, } = useStores();
    const { clientId } = useParams();
    const navigate = useNavigate();
    const onConfirmClick = async () => {
        const hubSpotId = await createOrder(Number(clientId));
        if (hubSpotId) {
            navigate(`/clients/${clientId}/return/${hubSpotId}`);
        }
    };
    useErrorToastEffect(error, 'Error with order creating');
    useErrorToastEffect(error, 'Error with price calculation');
    useEffect(() => {
        calculatePrice();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ColumnFlexContainer, null,
            React.createElement(InfoRow, { "$bold": true },
                React.createElement("span", null, "Price"),
                process ? (React.createElement("span", null, "...calculating")) : (React.createElement("span", { style: { fontSize: 24, lineHeight: '32px' } },
                    "\u00A3",
                    totalPrice))),
            React.createElement(InfoRow, null,
                React.createElement("span", null, "Date"),
                React.createElement("span", null, dayjs(date).format('DD MMMM YYYY'))),
            React.createElement(InfoRow, null,
                React.createElement("span", null, "Time slot"),
                React.createElement("span", null, `${timeSlot[0]}:00-${timeSlot[1]}:00`)),
            React.createElement(InfoRow, null,
                React.createElement("span", null, "Address"),
                React.createElement("span", null, address.address))),
        items
            .filter(item => item.amount > 0)
            .map((item, index) => (React.createElement(ViewItem, { amount: item.amount, comment: item.comment, title: item.title, key: index }))),
        React.createElement("p", { style: { textAlign: 'right', marginTop: 12 } },
            "Total items: ",
            items.reduce((acc, item) => acc + item.amount, 0)),
        React.createElement(BottomButtonsWrapper, null,
            React.createElement(Button, { onClick: onConfirmClick, full: true, kind: 'primary', loading: process, type: "button" }, "Confirm"))));
};
export default observer(TotalSheet);
