import { Header } from '#Components';
import { AddressSlide, DateSlide, ItemsSlide, TotalSheetSlide } from '#Components/StorageReturn/slides';
import { useStores } from '#Hooks';
import { ApiService } from '#Services';
import { getStorageItemsFromClient } from '#Services/storage';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
const api = new ApiService();
const getBlockTitleFromIndex = (full) => {
    return ['Address for return', ...(full ? [] : ['Items']), 'Date', 'Total sheet', 'Details'];
};
const StorageReturn = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const { storageReturnData } = useStores();
    const { clientId } = useParams();
    const isFull = params.get('full') === '1';
    const { data, error, isLoading, refetch } = useQuery({
        enabled: !storageReturnData.clientInfo,
        queryKey: ['client', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getClient(Number(clientId));
        },
    });
    useEffect(() => {
        if (isFull && data && !storageReturnData.items.length) {
            storageReturnData.setItems(getStorageItemsFromClient(data).map(i => ({ ...i, comment: '', max_amount: i.amount })) || []);
        }
    }, [data]);
    useEffect(() => {
        if (!storageReturnData.clientInfo && data) {
            storageReturnData.setClientInfo(data);
        }
    }, [data]);
    useEffect(() => {
        return () => {
            storageReturnData.clean();
        };
    }, []);
    const slidesToRender = [
        React.createElement(AddressSlide, { key: "address" }),
        ...(!isFull ? [React.createElement(ItemsSlide, { key: "storage" })] : []),
        React.createElement(DateSlide, { key: "date" }),
        React.createElement(TotalSheetSlide, { key: "totalSheet" }),
        // <DetailsSlide key="details" />,
    ];
    const onHeaderBackClick = () => {
        if (storageReturnData.currentStepIndex === 0) {
            return navigate(-1);
        }
        storageReturnData.setCurrentStepIndex(storageReturnData.currentStepIndex - 1);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, { onBackBtnClick: onHeaderBackClick, title: getBlockTitleFromIndex(isFull)[storageReturnData.currentStepIndex] }),
        slidesToRender.map((slide, index) => storageReturnData.currentStepIndex === index && slide)));
};
export default observer(StorageReturn);
