import axios from 'axios';
const apiService = axios.create({
    baseURL: '',
});
export default class ApiService {
    postResource = async (url, data, params, uploadProgress) => apiService.post(`${url}`, data, {
        params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
        ...(uploadProgress && {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                uploadProgress && uploadProgress(percentCompleted);
            },
        }),
    });
    patchResource = async (url, data, params) => apiService.patch(`${url}`, data, {
        params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
    });
    putResource = async (url, data, params) => apiService.put(`${url}`, data, {
        params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
    });
    getResource = async (url, params) => apiService.get(`${url}`, {
        params,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        },
    });
    postAddNewUnit = async (data, storageId) => {
        try {
            const res = await this.postResource(`/api/v1/storage/${storageId}/unit/`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    postAddNewItem = async (item) => {
        try {
            const res = await this.postResource(`/api/v1/storage/item_info`, item);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    postAddNewSpace = async (data, storageId, unitId) => {
        try {
            const res = await this.postResource(`/api/v1/storage/${storageId}/unit/${unitId}/space`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    putSpace = async (data, storageId, unitId, spaceId) => {
        try {
            const res = await this.putResource(`/api/v1/storage/${storageId}/unit/${unitId}/space/${spaceId}/`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    postAddNewFile = async (file, progress) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const res = await this.postResource(`/new-api/media/v1/storage`, formData, {}, progress);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getStorages = async () => {
        try {
            const res = await this.getResource(`/api/v1/storage`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getStorage = async (id, filter) => {
        try {
            const searchParam = filter ? `/?space_search=${filter}` : '/';
            const res = await this.getResource(`/api/v1/storage/${id}${searchParam}`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getAllStorages = async () => {
        try {
            const res = await this.getResource(`/api/v1/storage`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    // Storage return --
    postCreateHubSpotOrder = async (data) => {
        try {
            const res = await this.postResource(`/old-api/api/v1/warehouse/storage_return`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    postCancelHubSpotOrder = async (hubSpotId) => {
        try {
            const res = await this.postResource(`/old-api/api/v1/order/${hubSpotId}/cancel`, {});
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    calculateOrder = async (data) => {
        try {
            const res = await this.postResource(`/api/v1/calculate`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getReturnOrders = async (clientId) => {
        try {
            const res = await this.getResource(`/api/v1/storage/user/${clientId}/storage_return_orders`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getReturnOrder = async (hubSpotId) => {
        try {
            const res = await this.getResource(`/api/v1/storage/order/${hubSpotId}`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    // --
    getUnit = async (storageId, unitId) => {
        try {
            const res = await this.getResource(`/api/v1/storage/${storageId}/unit/${unitId}/`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getClients = async (name) => {
        try {
            const res = await this.getResource(`/api/v1/storage/user?name=${name}`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getClient = async (clientId) => {
        try {
            const res = await this.getResource(`/api/v1/storage/user/${clientId}/`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getAllClientPlans = async () => {
        try {
            const res = await this.getResource(`/api/v1/storage/plans`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    patchClient = async (clientId, data) => {
        try {
            const res = await this.patchResource(`/api/v1/storage/user/${clientId}/`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    patchUnit = async (unitId, data) => {
        try {
            const res = await this.patchResource(`/api/v1/unit/${unitId}`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    removeUnit = async (unitId) => {
        try {
            const res = await apiService.delete(`/api/v1/unit/${unitId}`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getItems = async () => {
        try {
            const res = await this.getResource(`/api/v1/storage/item_info`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    getSpace = async (storageId, unitId, spaceId) => {
        try {
            const res = await this.getResource(`/api/v1/storage/${storageId}/unit/${unitId}/space/${spaceId}/`);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    deleteSpace = async (storageId, unitId, spaceId, body) => {
        try {
            const tetRes = await apiService.delete(`/api/v1/storage/${storageId}/unit/${unitId}/space/${spaceId}/`, {
                data: body,
            });
            return tetRes;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    patchTransferSpace = async (data, storageId, unitId, spaceId) => {
        try {
            const res = await this.patchResource(`/api/v1/storage/${storageId}/unit/${unitId}/space/${spaceId}/`, data);
            return res.data;
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };
    // Auth
    login = async (email, password) => {
        const data = new FormData();
        data.append('username', email);
        data.append('password', password);
        const res = await this.postResource('/auth-api/auth/jwt/login', data);
        return res;
    };
    logout = async () => {
        const res = await this.postResource('/auth-api/auth/jwt/logout', {});
        return res;
    };
    checkUser = async () => {
        const res = await this.getResource('/auth-api/users/me');
        return res;
    };
}
