import React from 'react';
import { DesignFonts } from '../../../style/DesignFonts';
import styled from 'styled-components';
const Container = styled.div `
  display: flex;
  justify-content: center;
  margin-bottom: ${({ $bm }) => $bm || 32}px;

  @media screen and (max-width: 640px) {
    display: block;
    margin-bottom: 12px;
  }
`;
const SelectorContainer = styled.div `
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 70px;
  background-color: var(--main-gray);
  padding: 4px;
  width: ${({ $responsive }) => ($responsive ? `100%` : 'auto')};
`;
const Option = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.$selected ? 'white' : 'transparent')};
  padding: 8px 24px;
  border-radius: 70px;
  cursor: pointer;
  transition: 0.3s all ease;
  ${({ $font }) => $font || DesignFonts.headlineH5}
  width: 100%;
  white-space: nowrap;

  @media screen and (max-width: 640px) {
    padding: 8px 16px;
    ${DesignFonts.bodyDemiBold}
  }

  ${({ $customCss }) => $customCss};
`;
const ToggleSelector = ({ containerBottomMargin, options, selected = 0, setSelected, responsive, font, customCss, }) => {
    return (React.createElement(Container, { "$bm": containerBottomMargin },
        React.createElement(SelectorContainer, { "$responsive": responsive }, options.map((option, index) => (React.createElement(Option, { "$customCss": customCss, "$font": font, key: index, "$selected": selected === index, onClick: () => setSelected(index) }, option))))));
};
export default ToggleSelector;
