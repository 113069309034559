import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Container, ExtraPrice, SlotBlock, SlotBlockContent, SlotItem } from './Components';
import dayjs from 'dayjs';
import { DesignFonts } from '../../../../style/DesignFonts';
import { isTwoHoursSlot } from '#Utils/helpers';
import ToggleSelector from '../ToggleSelector';
import { useStores } from '#Hooks';
import { sixHoursTimeSlots, twoHoursTimeSlots } from '#Constants/dateConst';
import DatePicker from './DatePicker/DatePicker';
import { Button } from '#Components/shared';
import { BottomButtonsWrapper } from '../components';
const toggleCustomCss = `
   padding: 4px 16px;
    ${DesignFonts.bodyDemiBold}
`;
export const checkIfNotWeekend = (date) => {
    return date.getDay() !== 0 && date.getDay() !== 6;
};
export const checkIfDateIsAvailable = (date) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
        return false;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const checkedDate = new Date(date);
    checkedDate.setHours(0, 0, 0, 0);
    // Если проверяемая дата уже прошла
    if (checkedDate < today) {
        return false;
    }
    let currentDay = new Date(today);
    let workingDaysPassed = 0;
    // Считаем рабочие дни, начиная со следующего дня после текущего
    currentDay.setDate(currentDay.getDate() + 1);
    while (currentDay <= checkedDate) {
        // Проверяем, что день не выходной
        if (currentDay.getDay() !== 0 && currentDay.getDay() !== 6) {
            workingDaysPassed++;
        }
        // Если достигнуто 3 рабочих дня, проверяемая дата доступна
        if (workingDaysPassed >= 3) {
            return true;
        }
        currentDay.setDate(currentDay.getDate() + 1);
    }
    // Возвращаем false, если проверяемая дата не доступна
    return false;
};
const DateModule = () => {
    const { storageReturnData: { setCurrentStepIndex, currentStepIndex, date, timeSlot, setTimeSlot, setDate, isSixHoursTimeSlots, setIsSixHrsTimeSlot, }, } = useStores();
    const onNextClick = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    useEffect(() => {
        if (!checkIfNotWeekend(date || new Date())) {
            setDate(null);
        }
    }, []);
    useEffect(() => {
        if (isSixHoursTimeSlots && isTwoHoursSlot(timeSlot)) {
            setTimeSlot(sixHoursTimeSlots[0]);
        }
        else if (!isSixHoursTimeSlots && !isTwoHoursSlot(timeSlot)) {
            setTimeSlot(twoHoursTimeSlots[0]);
        }
    }, [isSixHoursTimeSlots]);
    const onSelectDate = (d) => {
        if (date === d)
            return;
        setDate(d);
    };
    const onSelectTimeSlot = (v) => {
        if (timeSlot[0] === v[0])
            return;
        setTimeSlot(v);
    };
    const getMinDate = () => {
        return dayjs().add(3, 'day').toDate();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(DatePicker, { minDate: getMinDate(), filterDate: checkIfDateIsAvailable, value: date, onChange: date => onSelectDate(date) }),
            React.createElement(SlotBlock, null,
                React.createElement(React.Fragment, null,
                    React.createElement(ToggleSelector, { containerBottomMargin: 12, customCss: toggleCustomCss, responsive: true, font: DesignFonts.bodyDemiBold, options: [
                            '6 hrs',
                            React.createElement(React.Fragment, null,
                                "2 hrs",
                                React.createElement(ExtraPrice, null, "+15%")),
                        ], selected: isSixHoursTimeSlots ? 0 : 1, setSelected: i => setIsSixHrsTimeSlot(i === 0) })),
                React.createElement(SlotBlockContent, null, (isSixHoursTimeSlots ? sixHoursTimeSlots : twoHoursTimeSlots).map((item, key) => (React.createElement(SlotItem, { onClick: () => onSelectTimeSlot(item), key: key, isChecked: item[0] === timeSlot[0] }, `${item[0]}:00 - ${item[1]}:00`)))))),
        React.createElement(BottomButtonsWrapper, null,
            React.createElement(Button, { onClick: onNextClick, full: true, 
                // $mobileSmall
                kind: 'primary', loading: false, type: "button" }, "Continue"))));
};
export default observer(DateModule);
